<template>
  <span :class="['lock-icon', {'btn btn-ghost-light': btns, 'd-inline-block mr-2': !btns}]"
        v-p-tooltip.top="tooltip"
        @click="toggle(isLocked ? 'unlock' : 'lock')">
    <fluency-icon :type="isLocked ? 'lock' : 'unlock'"
                  :class="{ 'locked-label': isLocked, 'text-muted': !isLocked}"/>
    <span v-if="lockLabel" class="d-block">{{isLocked ? 'Locked' : 'Unlocked'}}</span>
  </span>
</template>

<script>
export default {
  name: 'lockedField',
  props: {
    value: {
      type: String,
      default: ''
    },
    field: {
      type: String,
      default: ''
    },
    locked: {
      type: Boolean,
      default: undefined
    },
    lockLabel: {
      type: Boolean,
      default: false
    },
    btns: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: 'Locking prevents overwriting of this field when feed data or Blueprint changes.'
    }
  },
  data () {
    return {
      lockedSet: new Set()
    }
  },
  watch: {
    value: {
      handler () {
        if (this.value && this.value.length > 0) {
          this.lockedSet = new Set(this.value.split(','))
        } else {
          this.lockedSet = new Set()
        }
      },
      immediate: true
    },
    locked: {
      handler (val) {
        if (val === true) {
          this.toggle('lock')
        } else if (val === false) {
          this.toggle('unlock')
        }
      },
      immediate: true
    }
  },
  computed: {
    isLocked () {
      return this.lockedSet.has(this.field)
    }
  },
  methods: {
    toggle (evnt) {
      if (evnt === 'lock') {
        this.insert(this.field)
      } else if (evnt === 'unlock') {
        this.remove(this.field)
      }
    },
    remove () {
      if (this.lockedSet.has(this.field)) {
        this.lockedSet.delete(this.field)
        this.$emit('input', [...this.lockedSet].join(','))
        this.$emit('locked', false)
      }
    },
    insert () {
      if (!this.lockedSet.has(this.field)) {
        this.lockedSet.add(this.field)
        this.$emit('input', [...this.lockedSet].join(','))
        this.$emit('locked', true)
      }
    }
  }
}
</script>

<style scoped>
  .lock-icon:hover {
    cursor: pointer;
  }
</style>
